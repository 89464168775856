<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑导航' : '新增导航'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 模块名称 -->
      <a-form-item
        label="名称"
        name="name"
        :rules="[{ required: true, message: '请输入名称' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
          :maxlength="5"
        />
      </a-form-item>
      <!-- 顺序 -->
      <a-form-item
        label="顺序"
        name="sort"
        :rules="[{ required: true, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.sort"
        />
      </a-form-item>
      <!-- 路径 -->
      <a-form-item
        label="路径"
        name="path"
        :rules="[{ required: true, message: '请输入路径' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.path"
          :maxlength="500"
        />
      </a-form-item>
      <a-form-item
        label="icon"
        name="icon_urls"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="IconNav"
          :fileNumber="5"
          :imgSizeMode="3"
          :imgSizeWidth="100"
          :imgSizeHeight="100"
          imgSizeError="图片尺寸为 100 * 100"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'icon_urls') }"
          :fileList="formState.icon_urls"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持格式：.png .jpg，尺寸100x100</div>
        <div class="form-upload-list" v-if="formState.icon_urls.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.icon_urls"
            :key="index"
          >
            <ImageView
              class="upload-image upload-image-horizontal"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('icon_urls', index)"
            >
            </ImageView>
          </div>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { FrontSource} from '@/utils/constantList'
import ImageView from '@/components/ImageView'
import { frontSourceDetail, typeIdList, iconNavSave } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// source类型
const source = FrontSource.icon_nav
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// platform
let platform = ref(undefined)
// id
let id = ref(undefined)
// 短剧列表
let dramaList = ref([])
// 页面内容列表
let typeIdNameList = ref([])
// 剧集信息
let formState = reactive({
  // 文案
  name: undefined,
  // 顺序
  sort: undefined,
  // 地址
  path: undefined,
  // icon地址
  icon_urls: [],
  icon_url: undefined,
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  visible.value = true
  // 当前平台
  platform.value = params.platform
  // id
  id.value = params.id
  //获得数据详情
  if (id.value) {
    getFrontSourceDetail()
  } 
}


function getTypeIdList (type) {
  isLoading.value = true    
  typeIdList({ type: type }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      typeIdNameList.value = data
      // 获取配置详情

    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取配置详情
function getFrontSourceDetail () {
  isLoading.value = true
  const params = {
    source,
    id: id.value
  }
  frontSourceDetail(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 名称
      formState.name = data.name
      // 排序
      formState.sort = data.sort
      // 地址
      formState.path = data.path
      // icon地址
      formState.icon_url = data.icon_url
      formState.icon_urls = [{
        url: data.icon_url
      }]
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    visible.value = false
    formState.type = undefined
    formState.type_id = undefined
  }
}

// 表单校验
function validator (rule, value) {
  // 封面
  if (rule.field === 'sort') {
    if (!value) {
      return Promise.reject('请输入顺序')
    } else if (!Pub.REG_IS_POSITIVE_INTEGER(value)) {
      return Promise.reject('必须为正整数')
    }
    return Promise.resolve()
  }
}

// 提交
function touchSubmit () {
  formState.icon_url = formState.icon_urls[0].url
  RefForm.value.validate().then(() => {
    isLoading.value = true
    const params = {
      platform_id: platform.value,
      id: id.value,
      name: formState.name,
      sort: formState.sort,
      path: formState.path,
      icon_url: formState.icon_urls[0].url,
    }
    iconNavSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 清空列表
  if (key) { formState[key] = [] }
  // 允许
  return true
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}


// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.image-view.upload-image {
  height: 208px;
}
.image-view.upload-image-horizontal {
  width: 208px;
  height: 208px;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  // width: 118px;
  // height: 148px;
  width: 208px;
  height: 124px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>